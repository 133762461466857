import React from 'react';
// import GridGallery from "../components/gridGallery";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Banner from "../components/banner";
import RequestQuote from "../components/quote";

const GalleryIndex = () => {
    return (
        <Layout>
            <Seo title="Gallery" />
            <Banner />
            {/* <GridGallery /> */}
            <RequestQuote />
        </Layout >
    );
};

export default GalleryIndex;
